import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as s from './TeammateCard.module.scss'

const TeammateCard = (props) => {
  const { image, name, post, className, ...rest } = props

  return (
    <div {...rest} className={clsx(s.teammateCard, className)}>
      <GatsbyImage image={getImage(image)} alt="Teammate img" />
      <h3 className={s.name}>{name}</h3>
      <p className={s.position}>{post}</p>
    </div>
  )
}

TeammateCard.defaultProps = {
  className: '',
}

TeammateCard.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  post: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default TeammateCard
