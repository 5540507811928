import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Container } from 'react-bootstrap'

import WhyMeItem from './components/WhyMeItem'
import BulletItem from './components/BulletItem'
import * as s from './WhyMeSection.module.scss'

const WhyMeSection = (props) => {
  const { id: sectionId, heading, whyMe, bullets, className } = props

  return (
    <Container
      id={sectionId}
      as="section"
      className={clsx(s.whyMeSection, className)}
    >
      <h2>{heading}</h2>
      <ul className={s.listWrapper}>
        {whyMe.map(({ id, ...whyMeItem }) => (
          <WhyMeItem key={id} {...whyMeItem} />
        ))}
      </ul>
      <ul className={s.bulletWrapper}>
        {bullets.map(({ id, text }) => (
          <BulletItem key={id} text={text} />
        ))}
      </ul>
    </Container>
  )
}

WhyMeSection.defaultProps = {
  className: '',
}

WhyMeSection.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  whyMe: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string })
  ).isRequired,
  className: PropTypes.string,
}

export default WhyMeSection
