import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import CircleButton from '~components/CircleButton'

import * as s from './ServiceItem.module.scss'

const ServiceItem = (props) => {
  const { title, description, className, ...rest } = props

  return (
    <li {...rest} className={clsx(s.service, className)}>
      <h3 className={s.title}>{title}</h3>
      <div className={s.description}>{description}</div>
      <CircleButton variant="dark" arrowVariant="right" className={s.button} />
    </li>
  )
}

ServiceItem.defaultProps = {
  className: '',
}

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default ServiceItem
