import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

import TeammateCard from './components/TeammateCard'
import SwiperButtons from './components/SwiperButtons'
import * as s from './TeamSection.module.scss'

const TeamSection = (props) => {
  const { id: sectionId, heading, text, teammates, className } = props

  return (
    <section id={sectionId} className={clsx(s.teamSection, className)}>
      <Container>
        <h2>{heading}</h2>
        <p className={s.description}>{text}</p>
        <Swiper
          spaceBetween={24}
          slidesPerView="auto"
          modules={[Navigation, Pagination]}
          breakpoints={{
            768: { spaceBetween: 36 },
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          {teammates.map(({ id, ...teammate }) => (
            <SwiperSlide key={id}>
              <TeammateCard {...teammate} />
            </SwiperSlide>
          ))}

          <SwiperButtons />
        </Swiper>
      </Container>
    </section>
  )
}

TeamSection.defaultProps = {
  className: '',
}

TeamSection.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  teammates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      post: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
}

export default TeamSection
