import React from 'react'
import { graphql } from 'gatsby'

import SEO from '~components/seo'
import Homepage from '~containers/Homepage'

export const Head = () => <SEO />

const HomePage = ({ data }) => {
  const {
    heroSectionHeading,
    heroSectionText,
    heroSectionImg,
    servicesSectionHeading,
    servicesSectionText,
    servicesSectionDescription,
    services,
    teammatesSectionHeading,
    teammatesSectionText,
    teammates,
    whyMeSectionHeading,
    whyMe,
    bullets,
  } = data.datoCmsHomePage

  return (
    <Homepage
      heroSection={{
        heading: heroSectionHeading,
        text: heroSectionText,
        image: heroSectionImg,
      }}
      servicesSection={{
        heading: servicesSectionHeading,
        text: servicesSectionText,
        description: servicesSectionDescription,
        services,
      }}
      teammatesSection={{
        heading: teammatesSectionHeading,
        text: teammatesSectionText,
        teammates,
      }}
      whyMeSection={{
        heading: whyMeSectionHeading,
        whyMe,
        bullets,
      }}
    />
  )
}

export default HomePage

export const query = graphql`
  query Homepage {
    datoCmsHomePage {
      heroSectionHeading {
        value
      }
      heroSectionText {
        value
      }
      heroSectionImg {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [375, 750, 1024, 1440, 1920, 2048, 2880]
          placeholder: BLURRED
          imgixParams: { fit: "crop", auto: "compress,format" }
        )
      }

      servicesSectionHeading
      servicesSectionText
      servicesSectionDescription
      services {
        id
        title
        description
      }

      teammatesSectionHeading
      teammatesSectionText
      teammates {
        id
        image {
          gatsbyImageData(
            width: 285
            height: 320
            outputPixelDensities: [1, 1.5, 2]
            placeholder: BLURRED
            imgixParams: { fit: "crop", auto: "compress,format" }
          )
        }
        name
        post
      }

      whyMeSectionHeading
      whyMe {
        id
        iconName
        title
        description
      }
      bullets {
        id
        text
      }
    }
  }
`
