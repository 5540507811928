import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import clsx from 'clsx'

import ServiceItem from './components/ServiceItem'
import * as s from './ServicesSection.module.scss'

const ServicesSection = (props) => {
  const {
    id: sectionId,
    heading,
    text,
    description,
    services,
    className,
  } = props

  return (
    <Container
      id={sectionId}
      as="section"
      className={clsx(s.servicesSection, className)}
    >
      <h2>{heading}</h2>
      <div className={s.headlineWrapper}>
        <div className={s.headlineWrapper_headline}>{text}</div>
        <div className={s.headlineWrapper_description}>{description}</div>
      </div>
      <ul className={s.servicesWrapper}>
        {services.map(({ id, ...service }) => (
          <ServiceItem key={id} {...service} />
        ))}
      </ul>
    </Container>
  )
}

ServicesSection.defaultProps = {
  className: '',
}

ServicesSection.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
}

export default ServicesSection
