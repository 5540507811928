import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Icon from '~components/Icon'

import * as s from './BulletItem.module.scss'

const BulletItem = (props) => {
  const { text, className } = props

  return (
    <li className={clsx(s.bulletItem, className)}>
      <div className={s.icon}>
        <Icon name="icon-check" size={16} />
      </div>
      <p className={s.text}>{text}</p>
    </li>
  )
}

BulletItem.defaultProps = {
  className: '',
}

BulletItem.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default BulletItem
