import React from 'react'
import clsx from 'clsx'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import PropTypes from 'prop-types'
import { StructuredText } from 'react-datocms'
import { Container } from 'react-bootstrap'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import CircleButton from '~components/CircleButton'

import * as s from './HeroSection.module.scss'

const HeroSection = (props) => {
  const { id, heading, text, image, className } = props

  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e) => {
    e.preventDefault()
    gsap.to(window, {
      scrollTo: { y: '#services', offsetY: 78 },
      ease: 'power2',
    })
  }

  return (
    <section id={id} className={clsx(s.heroSection, className)}>
      <Container className={s.info}>
        <div className={s.title}>
          <StructuredText data={heading.value} />
        </div>
        <div className={s.description}>
          <StructuredText data={text.value} />
        </div>
        <CircleButton onClick={handleScroll} />
      </Container>
      <div className={s.bgImage}>
        <GatsbyImage
          image={getImage(image)}
          alt="hero image"
          objectFit="cover"
          className={s.bgImage_img}
        />
      </div>
    </section>
  )
}

HeroSection.defaultProps = {
  className: '',
}

HeroSection.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default HeroSection
