import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Icon from '~components/Icon'

import * as s from './WhyMeItem.module.scss'

const WhyMeItem = (props) => {
  const { iconName, title, description, className } = props

  return (
    <li className={clsx(s.whyMeItem, className)}>
      <div className={s.header}>
        <div className={s.icon}>
          <Icon name={iconName} size={28} />
        </div>
        <h3>{title}</h3>
      </div>
      <p className={s.description}>{description}</p>
    </li>
  )
}

WhyMeItem.defaultProps = {
  className: '',
}

WhyMeItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default WhyMeItem
