import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import Icon from '~components/Icon'

const CircleButton = (props) => {
  const { variant, arrowVariant, onClick, className } = props

  return (
    <Button
      variant={`circle-outline ${variant}`}
      onClick={onClick && onClick}
      aria-label="go to next section"
      className={className}
    >
      <Icon name={`icon-arrow-${arrowVariant}`} />
    </Button>
  )
}

CircleButton.defaultProps = {
  variant: 'light',
  arrowVariant: 'down',
  onClick: null,
  className: '',
}

CircleButton.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
  arrowVariant: PropTypes.oneOf(['down', 'right']),
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default CircleButton
