import React from 'react'

import HeroSection from './components/HeroSection'
import ServicesSection from './components/ServicesSection'
import TeamSection from './components/TeamSection'
import WhyMeSection from './components/WhyMeSection'
import * as s from './Homepage.module.scss'

const Homepage = ({
  heroSection,
  servicesSection,
  teammatesSection,
  whyMeSection,
}) => {
  return (
    <div className={s.container}>
      <HeroSection id="hero" {...heroSection} />
      <ServicesSection id="services" {...servicesSection} />
      <TeamSection id="team" {...teammatesSection} />
      <WhyMeSection id="guarantees" {...whyMeSection} />
    </div>
  )
}

export default Homepage
