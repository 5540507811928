import clsx from 'clsx'
import React from 'react'
import Icon from '~components/Icon'

const BUTTONS = ['prev', 'next']

const SwiperButtons = () => (
  <>
    {BUTTONS.map((side) => (
      <button
        key={side}
        type="button"
        name={`swiper-button-${side}`}
        aria-label={`swiper-button-${side}`}
        className={clsx('swiper-button', `swiper-button-${side}`)}
      >
        <Icon name="icon-chevron-left" size={26} />
      </button>
    ))}
  </>
)

export default SwiperButtons
